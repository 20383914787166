import * as React from "react"

import PickerScreen from '../../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="How far do you need to go?"
    options={[
      {
        label: "I just need the basics",
        link: "/app/tool/canva/"
      },
      {
        label: "I need to create graphs and charts",
        link: "/app/tool/pitch-or-google-slides/"
      }
  ]}/>
)

export default Picker;


